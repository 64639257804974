<template>
  <div class="content-box-padding">
    <div class="sub-title">智能航空监测预报平台</div>
    <div class="line-text" style="margin-top: 20px">
      基于多源气象观测资料与AI大数据挖掘技术，实现大雾、强降水等航空危险天气智能识别和跟踪，为天气复盘提供研判依据。基于气象管制数据融合，为管制部门提供区域预警产品，实现行业用户与气象科技创新成果深度结合。
      同时，利用先进的短临外推算法模型，采用民航检验标准，提供精细化雷达回波和降水的外推临近预报产品与实时检验功能，同时预报员可在系统内进行机场警报、MDRS等预警制作，方便与管制部门等用户单位进行协同决策，极大减轻预报员业务压力。
    </div>
    <img
      src="~assets\image\business\智能航空监测预报平台.png"
      alt="智能航空监测预报平台"
      style="margin: 40px auto 80px auto"
    />

    <div class="sub-title">高分辨率快速循环更新同化预报平台</div>
    <div class="line-text" style="margin-top: 20px">
      实现多源观测资料同化，提供多尺度的分钟级高分辨率快速循环更新同化预报产品，可对地面、高空、不稳定度、危险天气等要素进行多样化模式分析，为行业用户的预报业务运行与战略决策提供高精度预报产品，提升预报准确率与工作效率。
    </div>
    <img
      src="~assets\image\business\高分辨率快速循环更新同化预报平台.png"
      alt="高分辨率快速循环更新同化预报平台"
      style="margin: 40px auto 0px auto"
    />
  </div>
</template>
